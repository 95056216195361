import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import clsx from 'clsx';
import moment from 'moment';
import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import { makeStyles } from '@material-ui/styles';

import * as actions from '../../../store/actions'

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  Divider,
  Typography,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
  Chip
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { Info } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {}
}));

const MerchantSummary = props => {
  const { className, merchant, onSubmitClick, is_dm_admin, is_admin, account_merchant_subscription_enabled, onMerchantSubscriptionToggle, current_user, onFetchSearchCounts,search_counts,plan_level, ...rest } = props;
  const [fein, setFein] = useState('')
  const [legal_name, setLegalName] = useState('')
  const [dba, setDba] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [business_phone, setBusinessPhone] = useState('')
  const [business_startdate, setBusinessStartDate] = useState('')
  const [industry, setIndustry] = useState('')
  const [has_merchant_subscription, setHasMerchantSubscription] = useState(false)
  
  const classes = useStyles();

  useEffect(() => {
    if(merchant && merchant.id){
      setFein(merchant.fein || '')
      setLegalName(merchant.legal_name || '')
      setDba(merchant.dba || '')
      setCity(merchant.city || '')
      setState(merchant.state || '')
      setBusinessPhone(merchant.business_phone || '')
      setBusinessStartDate(merchant.business_startdate || '')
      setIndustry(merchant.industry || '')
      setHasMerchantSubscription(merchant.has_merchant_subscription || false)
    }
  },[merchant])

  let subscriptionToggle = null

  const [show_search_history, setShowSearchHistory] = useState(false)
  const toggleSearchHistory = () => {
    setShowSearchHistory(!show_search_history)
  }

  useEffect(() => {
    if (plan_level === 'PRO' || plan_level !== 'PRO'){
      onFetchSearchCounts(fein)
    }
  }, [fein])

  if ((is_dm_admin || is_admin) && account_merchant_subscription_enabled){
    subscriptionToggle = (
      <Grid
        item
        md={12}
        xs={12}
      >
        <FormControlLabel
          control={<Switch 
            checked={has_merchant_subscription}
            onChange={onMerchantSubscriptionToggle}
            name="merchant_subscription_enabled"
            color="primary"
          />}
          label="Subscription Enabled"
        />
      </Grid>
    )
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Merchant Info"
        action={subscriptionToggle}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={2}
            xs={2}
            align={'right'}
          >
            <Typography variant="h5">EIN or BN:</Typography>
          </Grid>
          <Grid
            item
            md={10}
            xs={10}
          >
            <Typography>{fein}</Typography>
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            align={'right'}
          >
            <Typography variant="h5">Legal Name:</Typography>
          </Grid>
          <Grid
            item
            md={10}
            xs={10}
          >
            <Typography>{legal_name}</Typography>
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            align={'right'}
          >
            <Typography variant="h5">City, State:</Typography>
          </Grid>
          <Grid
            item
            md={10}
            xs={10}
          >
            <Typography>{city}, {state}</Typography>
          </Grid>
          { dba !== '' ?
            <React.Fragment>
              <Grid
                item
                md={2}
                xs={2}
                align={'right'}
              >
                <Typography variant="h5">Doing Business As:</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
              >
                <Typography>{dba}</Typography>
              </Grid>
            </React.Fragment>
          : null }
          { business_phone !== '' ?
            <React.Fragment>
              <Grid
                item
                md={2}
                xs={2}
                align={'right'}
              >
                <Typography variant="h5">Business Phone:</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
              >
                <Typography>{business_phone}</Typography>
              </Grid>
            </React.Fragment>
          : null }
          { business_startdate !== '' ?
            <React.Fragment>
              <Grid
                item
                md={2}
                xs={2}
                align={'right'}
              >
                <Typography variant="h5">Business Start Date:</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
              >
                <Typography>{business_startdate}</Typography>
              </Grid>
            </React.Fragment>
          : null }
          { industry !== '' ?
            <React.Fragment>
              <Grid
                item
                md={2}
                xs={2}
                align={'right'}
              >
                <Typography variant="h5">Industry:</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
              >
                <Typography>{industry}</Typography>
              </Grid>
            </React.Fragment>
          : null }
        </Grid>
      </CardContent>
      <Divider />
      <React.Fragment>
      {is_dm_admin || (merchant && merchant.added_by_account_id == current_user.account_id && current_user.is_admin) ? 
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography style={{ textAlign: 'right' }}>
                  Added by {merchant.added_by_user} - {merchant.added_by}, on {moment(merchant.created_at).format('MM/DD/YYYY')}
                  <Tooltip title="Only admin users for your company can see who entered this record.  This is not displayed to any other DataMerch user.">
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
          </Grid>
        </CardActions>
      : null }
      <Divider />
      { (plan_level === 'PRO' || plan_level !== 'PRO') ? 
      <CardHeader
        title={
          <React.Fragment>
            Search History {search_counts.length ? <Chip label={search_counts.length} size="small" variant="outline" className={classes.chip} /> : null }
            <IconButton aria-label="settings" onClick={toggleSearchHistory}>
              { show_search_history ?
                <KeyboardArrowUpIcon />
                : 
                <KeyboardArrowDownIcon />
              }
            </IconButton>
          </React.Fragment>
        }
      />
      : null }
      {show_search_history && (plan_level === 'PRO' || plan_level !== 'PRO') ? 
        <React.Fragment>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Typography variant="body1">
                    This merchant has been searched the following times in the last 6 months:
                  </Typography>
                  {search_counts.map(search_count => (
                    <Typography variant="body1">
                      {moment(search_count.created_at).format('MM/DD/YYYY')} at  {moment(search_count.created_at).format('h:mm a')}
                    </Typography>
                  ))} 
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  
                </Grid>
            </Grid>
          </CardContent>
        </React.Fragment>
      : null
      }
      </React.Fragment>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    current_user: state.auth.user,
    is_dm_admin: state.auth.user.is_dm_admin,
    is_admin: state.auth.user.is_admin,
    account_merchant_subscription_enabled: state.auth.user.account_merchant_subscription_enabled,
    search_counts: state.searchCount.search_counts,
    plan_level: state.auth.user.plan_level 
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchSearchCounts: (search) => dispatch(actions.fetchSearchCounts(search))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MerchantSummary, axios))